/* eslint-disable */
import React from 'react'
import {
  Modal,
  Row,
  Col,
  //  Skeleton,
  Button,
} from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined, PlayCircleFilled } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/event/actions'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
// import ViewSDKClient from './Pdfviewer'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

// import Scrollbars from 'react-custom-scrollbars'
import SessionList from './SessionList'

import './agenda.less'

/* eslint-disable prefer-destructuring,  no-unused-vars */
// const { TabPane } = Tabs
const mapStateToProps = ({ virtualSettings, agenda, virtualEvent }) => ({
  virtualSettings,
  agenda,
  virtualEvent,
})

@connect(mapStateToProps)
class Agenda extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })

  // }
  state = {
    numPages: null,
  }

  // componentDidMount() {
  //   const viewSDKClient = new ViewSDKClient()
  //   viewSDKClient.ready().then(() => {
  //     /* Invoke file preview */
  //     /* By default the embed mode will be Full Window */
  //     viewSDKClient.previewFile('adobe-dc-view', {})
  //   })
  // }

  onError = err => {
    console.log('error in opening file')
    console.log(err)
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  initiateSession = () => {
    const {
      dispatch,
      agenda: { agendas },
      virtualEvent: { currenSceneAgenda },
    } = this.props
    const theBaseAgenda =
      Object.keys(agendas).length !== 0
        ? agendas[Object.keys(agendas)[0]]
        : currenSceneAgenda[Object.keys(currenSceneAgenda)[0]]
    const theBaseSession = theBaseAgenda.agendaItems[Object.keys(theBaseAgenda.agendaItems)[0]]
    const id = Object.keys(theBaseAgenda.agendaItems)[0]
    const scene = theBaseSession.assignedRoom
    const url = theBaseSession.url
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
    dispatch({
      type: actions.FILTER_SCENE,
      payload: {
        id: scene,
        navigated: true,
        componentRendered: false,
        sessionID: id,
        currentSessionURL: url,
        callScene: true,
        chatToggle: theBaseSession.chatToggle,
        pollingToggle: theBaseSession.pollingToggle,
        qandaToggle: theBaseSession.qandaToggle,
        reactionsToggle: theBaseSession.reactionsToggle,
      },
    })
    // dispatch(push(`${pathname}?scene=${scene}&session=${id}`))
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
  }

  render() {
    const {
      virtualSettings: { isAgendaOpen, isVirtualMobileView },
      agenda: { agendas, loading },
    } = this.props
    const { numPages } = this.state

    const showcase = Object.entries(agendas)
      .map(key => {
        return (
          // <TabPane
          //   tab={
          //     <span>
          //       {key[1].name}
          //       <br />
          //       {key[1].date}
          //     </span>
          //   }
          //   key={key[0]}
          // >
          <SessionList
            sessions={key[1].agendaItems}
            isVirtualMobileView={isVirtualMobileView}
            loading={loading}
            agendaType="global"
          />
          // </TabPane>
        )
      })
      .reverse()
    return (
      <div>
        <Modal
          visible={isAgendaOpen}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="agendaModal"
          maskClosable={false}
          mask
          width="60rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={
            <CloseCircleOutlined
              style={{
                color: 'white',
                fontSize: '25px',
                width: '56px',
                height: '56px',
                left: '0px',
              }}
            />
          }
          maskStyle={{ backgroundColor: 'rgba(15, 12, 41, 0.9)' }}
        // destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={6} className="text-center" />
            <Col span={12} className="text-center">
              <h3>Agenda</h3>
              {/* <h5><b>#FlipkartConnect2020</b></h5> */}
              {isVirtualMobileView ? (
                <div xs={12} md={0} lg={0} xl={0} className="text-center">
                  <br />
                  <Button
                    type="primary"
                    shape="round"
                    // data-url={item[1].url}
                    // data-id={item[0]}
                    // data-room={item[1].assignedRoom}
                    icon={<PlayCircleFilled />}
                    onClick={this.initiateSession}
                    size="large"
                  >
                    Join Live Session
                  </Button>
                </div>
              ) : (
                ''
              )}
            </Col>

            {/* <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col> */}
          </Row>
          <Row gutter={24} id="modalContent" className="modalContent">
            <Scrollbars style={{ height: 'calc(100vh - 20rem)' }}>
              <Row align="middle">
                <Col span={24} className="text-center">
                  {/* <Skeleton loading={loading} active>
                  <Tabs tabPosition={isVirtualMobileView ? 'top' : 'left'}>
                  {showcase}
                  </Tabs>
                </Skeleton> */}
                  {/* <div id="adobe-dc-view" className="full-window-div" /> */}
                  <div className="Example">
                    <div className="Example__container">
                      <div className="Example__container__document">
                        <Document file='resources/documents/agenda.pdf' onLoadSuccess={this.onDocumentLoadSuccess}>
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                          ))}
                        </Document>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Scrollbars>
          </Row>
          {/* <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row> */}
        </Modal>
      </div>
    )
  }
}

export default Agenda
