import React from 'react'
import { Col, Layout, Row } from 'antd'
import styles from './style.module.scss'

class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    return (
      <Layout>
        <Layout.Content>
          <Row>
            <Col md={12} sm={24} xs={24}>
              <div
                className={styles.left}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <div
                className={styles.right}
              >
                <div style={{ minWidth: '400px' }}>
                  {children}
                </div>

              </div>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
